import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import compose from "recompose/compose";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import {
  // Badge,
  IconButton,
  // Popover,
  Toolbar,
  Typography
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  Close as CloseIcon,
  // NotificationsOutlined as NotificationsIcon,
  Input as InputIcon
} from "@material-ui/icons";
import { getNotifications } from "services/notification";
// import { NotificationList } from "./components";
import styles from "./styles";
import { UserContext } from "../../../../services/auth";

class Topbar extends Component {
  signal = true;

  state = {
    notifications: [],
    notificationsLimit: 4,
    notificationsCount: 0,
    notificationsEl: null
  };

  async getNotifications() {
    try {
      const { notificationsLimit } = this.state;

      const { notifications, notificationsCount } = await getNotifications(
        notificationsLimit
      );

      if (this.signal) {
        this.setState({
          notifications,
          notificationsCount
        });
      }
    } catch (error) {
      return;
    }
  }

  componentDidMount() {
    this.signal = true;
    this.getNotifications();
  }

  componentWillUnmount() {
    this.signal = false;
  }

  handleShowNotifications = event => {
    this.setState({
      notificationsEl: event.currentTarget
    });
  };

  handleCloseNotifications = () => {
    this.setState({
      notificationsEl: null
    });
  };

  render() {
    const {
      classes,
      className,
      title,
      isSidebarOpen,
      onToggleSidebar
    } = this.props;
    // const {
    //   notifications,
    //   otificationsCount,
    //   notificationsEl
    // } = this.state;

    const rootClassName = classNames(classes.root, className);
    // const showNotifications = Boolean(notificationsEl);

    return (
      <UserContext.Consumer>
        {({ logoutUser }) => (
          <Fragment>
            <div className={rootClassName}>
              <Toolbar className={classes.toolbar}>
                <IconButton
                  className={classes.menuButton}
                  onClick={onToggleSidebar}
                  variant="text"
                >
                  {isSidebarOpen ? <CloseIcon /> : <MenuIcon />}
                </IconButton>
                <Typography className={classes.title} variant="h4">
                  {title}
                </Typography>
                {/* <IconButton
              className={classes.notificationsButton}
              onClick={this.handleShowNotifications}
            >
              <Badge
                badgeContent={notificationsCount}
                color="primary"
                variant="dot"
              >
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
                <IconButton
                  className={classes.signOutButton}
                  onClick={logoutUser}
                >
                  <InputIcon />
                </IconButton>
              </Toolbar>
            </div>
            {/* <Popover
              anchorEl={notificationsEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              onClose={this.handleCloseNotifications}
              open={showNotifications}
              transformOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
            >
              <NotificationList
                notifications={notifications}
                onSelect={this.handleCloseNotifications}
              />
            </Popover> */}
          </Fragment>
        )}
      </UserContext.Consumer>
    );
  }
}

Topbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isSidebarOpen: PropTypes.bool,
  onToggleSidebar: PropTypes.func,
  title: PropTypes.string
};

Topbar.defaultProps = {
  onToggleSidebar: () => {}
};

export default compose(
  withRouter,
  withStyles(styles)
)(Topbar);

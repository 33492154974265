const LOCAL_SETTINGS = {
  ws: "ws://localhost:8000/graphql",
  http: "http://localhost:8000/graphql"
};

const PROD_SETTINGS = {
  ws: "wss://gosu-api.appspot.com/graphql",
  http: "https://gosu-api.appspot.com/graphql"
};

/**
 * Get the settings that bet is using for the apollo client
 * This returns the entire settings dictionary, so http or wss must be pulled out from it.
 * Common uses the Bet servers but this is arbitrary as the interface in common for login and tokens should be universal for all our services.
 */
export const apolloSettings = () => {
  if (window.location.hostname === "localhost") {
    return LOCAL_SETTINGS;
  }
  return PROD_SETTINGS;
};

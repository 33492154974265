export const white = "#FFFFFF";

export const grey = {
  50: "#FAFAFA",
  100: "#F5F5F5",
  200: "#EEEEEE",
  300: "#E0E0E0",
  400: "#BDBDBD",
  500: "#9E9E9E",
  600: "#757575",
  700: "#616161",
  800: "#424242",
  900: "#212121"
};

export const black = "#000000";

export const yellow = "#F29225";

export const green = "#02a16c";

export const orange = "#f28225";

export const red = "#FF4F4D";

export const primary = {
  main: yellow,
  light: "#f4a750",
  dark: "#a96619"
};

export const danger = {
  main: red,
  light: "#ff7270",
  dark: "#b23735"
};

export const success = {
  main: green,
  light: "#34b389",
  dark: "#01704b"
};

import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { Dashboard as DashboardLayout } from "layouts";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import { Check as CheckmarkIcon, Close as CloseIcon } from "@material-ui/icons";
import { Query, Mutation } from "react-apollo";
import moment from "moment";

import { OPEN_APPLICATIONS } from "../../gql/queries";
import { APPROVE_APPLICATION, DECLINE_APPLICATION } from "../../gql/mutations";

const styles = theme => ({
  root: {
    width: "100%",
    height: "calc(100% - 200px)",
    marginTop: "30px",
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  },
  column: {
    minWidth: 200,
    overflowY: "scroll",
    overflowX: "hidden"
  },
  approve: {
    backgroundColor: theme.palette.success.main,
    borderColor: theme.palette.success.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.success.light,
      borderColor: theme.palette.success.light
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: theme.palette.success.light,
      borderColor: theme.palette.success.light
    }
  },
  decline: {
    backgroundColor: theme.palette.danger.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.danger.light,
      borderColor: theme.palette.danger.light
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: theme.palette.danger.light,
      borderColor: theme.palette.danger.light
    }
  }
});

class Dashboard extends Component {
  render() {
    const { classes } = this.props;
    return (
      <DashboardLayout title="Applications">
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Approve</TableCell>
                <TableCell>Decline</TableCell>
                <TableCell className={classes.column}>Name</TableCell>
                <TableCell className={classes.column}>Email</TableCell>
                <TableCell className={classes.column}>Date</TableCell>
                <TableCell className={classes.column}>Game</TableCell>
                <TableCell className={classes.column}>Platform</TableCell>
                <TableCell className={classes.column}>Phone</TableCell>
                <TableCell className={classes.column}>Skype</TableCell>
                <TableCell className={classes.column}>Summary</TableCell>
                <TableCell className={classes.column}>YouTube</TableCell>
                <TableCell className={classes.column}>Mixer</TableCell>
                <TableCell className={classes.column}>LinkedIn</TableCell>
                <TableCell className={classes.column}>Discord</TableCell>
                <TableCell className={classes.column}>Other</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <Query query={OPEN_APPLICATIONS} errorPolicy="all">
                {({ data }) => {
                  const applications = (data && data.applications) || [];
                  return applications.map((application, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Mutation
                          mutation={APPROVE_APPLICATION}
                          refetchQueries={() => ["openApplications"]}
                          errorPolicy="all"
                        >
                          {approveApplication => {
                            return (
                              <IconButton
                                className={classes.approve}
                                aria-label="approve"
                                onClick={async () => {
                                  try {
                                    await approveApplication({
                                      variables: {
                                        applicationId: application.id
                                      }
                                    });
                                  } catch (e) {
                                    console.log(e.message);
                                  }
                                }}
                              >
                                <CheckmarkIcon />
                              </IconButton>
                            );
                          }}
                        </Mutation>
                      </TableCell>
                      <TableCell>
                        <Mutation
                          mutation={DECLINE_APPLICATION}
                          refetchQueries={() => ["openApplications"]}
                          errorPolicy="all"
                        >
                          {declineApplication => {
                            return (
                              <IconButton
                                className={classes.decline}
                                aria-label="decline"
                                onClick={async () => {
                                  console.log(application);
                                  try {
                                    await declineApplication({
                                      variables: {
                                        applicationId: application.id
                                      }
                                    });
                                  } catch (e) {
                                    console.log(e.message);
                                  }
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            );
                          }}
                        </Mutation>
                      </TableCell>
                      <TableCell className={classes.column}>
                        {application.user.fullName}
                      </TableCell>
                      <TableCell className={classes.column}>
                        {application.user.email}
                      </TableCell>
                      <TableCell className={classes.column}>
                        {moment(application.createdAt).format("M/D/YY h:mma")}
                      </TableCell>
                      <TableCell className={classes.column}>
                        {application.game.name}
                      </TableCell>
                      <TableCell className={classes.column}>
                        {application.platform.name}
                      </TableCell>
                      <TableCell className={classes.column}>
                        {application.phoneNumber}
                      </TableCell>
                      <TableCell className={classes.column}>
                        {application.skypeUsername}
                      </TableCell>
                      <TableCell className={classes.column}>
                        <div style={{ height: 43 }}>{application.summary}</div>
                      </TableCell>
                      <TableCell className={classes.column}>
                        {application.youtubeLink}
                      </TableCell>
                      <TableCell className={classes.column}>
                        {application.mixerLink}
                      </TableCell>
                      <TableCell className={classes.column}>
                        {application.linkedinLink}
                      </TableCell>
                      <TableCell className={classes.column}>
                        {application.discordLink}
                      </TableCell>
                      <TableCell className={classes.column}>
                        {application.otherLink}
                      </TableCell>
                    </TableRow>
                  ));
                }}
              </Query>
            </TableBody>
          </Table>
        </Paper>
      </DashboardLayout>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Dashboard);

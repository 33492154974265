import gql from "graphql-tag";

export const GET_ME = gql`
  query me {
    me {
      id
      fullName
      imageUrl
      email
      username
      rating
      role
    }
  }
`;

export const OPEN_APPLICATIONS = gql`
  query openApplications {
    applications(open: true) {
      id
      phoneNumber
      skypeUsername
      summary
      youtubeLink
      mixerLink
      linkedinLink
      discordLink
      otherLink
      createdAt
      game {
        id
        name
      }
      platform {
        id
        name
      }
      user {
        id
        fullName
        email
      }
    }
  }
`;

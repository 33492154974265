import React, { Component } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
// import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from "@material-ui/styles";
// import { chartjs } from './helpers';
import theme from "./theme";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./assets/scss/index.scss";
import Routes from "./Routes";
import {
  userDeleteToken,
  userTokenValidBefore,
  userSetToken,
  userRefreshToken,
  userSetApolloCache,
  UserContext
} from "./services/auth";
import { ApolloProvider } from "react-apollo";
import { apolloCreateClient } from "./services/apollo";
import { apolloSettings } from "./config";

const browserHistory = createBrowserHistory();

// Configure ChartJS
// Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
//   draw: chartjs.draw
// });

export default class App extends Component {
  constructor(props) {
    super(props);
    const signedIn = userTokenValidBefore();
    this.state = {
      signedIn
    };
    this.client = apolloCreateClient(this.logoutUser, apolloSettings());
  }
  componentDidMount() {
    userSetApolloCache(this.client);
    userRefreshToken(this.client);
  }
  signInUser = async token => {
    if (token) {
      userSetToken(token);
      // It is important to do reset store here because reset store will refire all queries and we want all queries to be refired now that they have logged in.
      await this.client.resetStore();
      userSetApolloCache(this.client);
      this.setState({ signedIn: true });
    }
  };
  logoutUser = () => {
    userDeleteToken();
    this.client.resetStore();
    this.setState({ signedIn: false });
  };
  render() {
    return (
      <ThemeProvider theme={theme}>
        <ApolloProvider client={this.client}>
          <UserContext.Provider
            value={{
              signedIn: this.state.signedIn,
              signInUser: this.signInUser,
              logoutUser: this.logoutUser
            }}
          >
            <Router history={browserHistory}>
              <Routes />
            </Router>
          </UserContext.Provider>
        </ApolloProvider>
      </ThemeProvider>
    );
  }
}

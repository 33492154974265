import { white, black } from "../common/colors";

export default {
  common: {
    black,
    white,
    neutral: "#E4E7EB",
    muted: "#9EA0A4"
  },
  primary: {
    contrastText: white,
    main: "#F29225",
    light: "#fdefde",
    dark: "#bf6b0b"
  },
  secondary: {
    contrastText: white,
    main: "#7d58ff",
    light: "",
    dark: "#37248F"
  },
  success: {
    contrastText: white,
    main: "#02a16c",
    light: "#34b389",
    dark: "#01704b"
  },
  info: {
    contrastText: white,
    main: "#1070CA",
    light: "#F1FBFC",
    dark: "#007489"
  },
  warning: {
    contrastText: white,
    main: "#f28225",
    light: "#f49b50",
    dark: "#a95b19"
  },
  danger: {
    contrastText: white,
    main: "#FF4F4D",
    light: "#ff7270",
    dark: "#b23735"
  },
  text: {
    primary: "#12161B",
    secondary: "#66788A",
    disabled: "#A6B1BB"
  },
  background: {
    default: "#f8fafc",
    dark: "#172B4D",
    paper: white
  },
  border: "#DFE3E8",
  divider: "#DFE3E8"
};

import gql from "graphql-tag";

export const TOKEN_REFRESH_MUTATION = gql`
  mutation tokenRefresh {
    tokenRefresh {
      token
      user {
        id
        fullName
        imageUrl
        email
        username
        rating
        role
      }
    }
  }
`;

export const SIGN_IN = gql`
  mutation signIn($login: String!, $password: String!) {
    signIn(login: $login, password: $password) {
      token
      user {
        id
        fullName
        imageUrl
        email
        username
        rating
        role
      }
    }
  }
`;

export const APPROVE_APPLICATION = gql`
  mutation approveApplication($applicationId: ID!) {
    approveApplication(applicationId: $applicationId) {
      id
      open
      approved
    }
  }
`;

export const DECLINE_APPLICATION = gql`
  mutation declineApplication($applicationId: ID!) {
    declineApplication(applicationId: $applicationId) {
      id
      open
      approved
    }
  }
`;

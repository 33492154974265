/*
This service is responsible for the creation of the apollo client which handles
all of our graphql network traffic to the server.  Authentication, retries, errors,
cache, and the endpoint are all determined here.
*/
import { from } from "apollo-link";
import { onError } from "apollo-link-error";
import { RetryLink } from "apollo-link-retry";
import { HttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";

import { userGetToken } from "../auth";

/*
The auth link places the token into the authorization header if it exists
and if it does not exist then it provides no authorization header.  This sets the
context for other links down the chain and has a slightly different format than other
links.
https://github.com/apollographql/apollo-link/tree/master/packages/apollo-link-context
*/
const authLink = setContext(async (_, { headers = {} }) => {
  const token = userGetToken();
  return {
    headers: {
      ...headers,
      "x-token": token
    }
  };
});

/**
 * HTTP link selects the address that we connect to for graphql requests.  This
 * should be QA in staging, LOCAL on local, and prod otherwise.
 *
 * https://github.com/apollographql/apollo-link/tree/master/packages/apollo-link-http
 *
 * @param settings {ApolloSettings} settings to connect to
 */
const getHttpLink = settings => {
  return new HttpLink({
    uri: settings.http
  });
};

/*
Retry Link determines how many attempts we should make to retry a failed
network call.  This only triggers on a network error and not on graphql errors.
TODO: by the docuementation you can write a custom retryIf clause which determines
if you should retry the call and I would want to retry only on certain HTTP error
codes but I can't seem to get the error code out of the fields, some more time needs
to be spent in it.
https://github.com/apollographql/apollo-link/tree/master/packages/apollo-link-retry
*/
const retryLink = new RetryLink({
  attempts: {
    max: 3
  }
});

/*
Error Link is a handler of what errors, if any, come back through the
graphql interface.  This is probably
https://github.com/apollographql/apollo-link/tree/master/packages/apollo-link-error
*/

const customErrorHandler = (onLogout, errorResp) => {
  const { graphQLErrors, networkError } = errorResp;
  if (graphQLErrors) {
    console.log(`[GraphQL error]: ${JSON.stringify(graphQLErrors)}`);
    if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0][0]) {
      const code = graphQLErrors[0][0].code;
      if (["1201", "1202"].indexOf(code) > -1) {
        onLogout();
      }
    }
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
};

const cache = new InMemoryCache({
  dataIdFromObject: o => o.id
});

/**
 * Call this to create an apollo client.
 *
 * This sets up all of the retry and error handling
 */
export const apolloCreateClient = (onLogout, settings) => {
  const errorLink = onError(errorResp =>
    customErrorHandler(onLogout, errorResp)
  );
  const httpLink = getHttpLink(settings);
  return new ApolloClient({
    connectToDevTools: window.location.hostname === "localhost",
    link: from([authLink, retryLink, errorLink, httpLink]),
    cache
  });
};

import React, { Component } from "react";
import {
  // Link,
  Redirect,
  withRouter
} from "react-router-dom";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import validate from "validate.js";
import _ from "underscore";
import { withStyles } from "@material-ui/core";
import {
  Grid,
  Button,
  // IconButton,
  CircularProgress,
  TextField,
  Typography
} from "@material-ui/core";
// import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
// import { Facebook as FacebookIcon, Google as GoogleIcon } from 'icons';
import styles from "./styles";
import schema from "./schema";
import { Mutation } from "react-apollo";
import { UserContext } from "../../services/auth";
import { SIGN_IN } from "../../gql/mutations";

class SignIn extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      values: {
        email: "",
        password: ""
      },
      touched: {
        email: false,
        password: false
      },
      errors: {
        email: null,
        password: null
      },
      isValid: false,
      submitError: null
    };
  }

  generateErrorMessage = error => {
    if (error.graphQLErrors && error.graphQLErrors[0]) {
      const errorTitle = error.graphQLErrors[0].message;
      if (errorTitle) {
        return errorTitle;
      } else {
        return "Server Error, Please try again.";
      }
    } else if (error.networkError) {
      return "Network connectivity error, please try again.";
    }
  };

  // handleBack = () => {
  //   const { history } = this.props;

  //   history.goBack();
  // };

  validateForm = _.debounce(() => {
    const { values } = this.state;

    const newState = { ...this.state };
    const errors = validate(values, schema);

    newState.errors = errors || {};
    newState.isValid = errors ? false : true;

    this.setState(newState);
  }, 300);

  handleFieldChange = (field, value) => {
    const newState = { ...this.state };

    newState.submitError = null;
    newState.touched[field] = true;
    newState.values[field] = value;

    this.setState(newState, this.validateForm);
  };

  handleSignIn = async (e, signIn, signInUser) => {
    e.preventDefault();
    try {
      const { history } = this.props;
      const { values } = this.state;

      const res = await signIn({
        variables: {
          login: values.email,
          password: values.password
        }
      });

      const user =
        (res && res.data && res.data.signIn && res.data.signIn.user) || {};
      if (user.role === "ADMIN") {
        const token =
          (res && res.data && res.data.signIn && res.data.signIn.token) || null;
        signInUser(token);
        history.push("/");
      } else {
        this.setState({
          submitError: "You don't have Admin privileges."
        });
      }
    } catch (error) {
      const submitError = this.generateErrorMessage(error);
      this.setState({
        submitError
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { values, touched, errors, isValid, submitError } = this.state;

    const showEmailError = touched.email && errors.email;
    const showPasswordError = touched.password && errors.password;

    if (this.context.signedIn) {
      return <Redirect to="/" />;
    }

    return (
      <UserContext.Consumer>
        {({ signInUser }) => (
          <Mutation mutation={SIGN_IN}>
            {(signIn, { loading }) => {
              return (
                <div className={classes.root}>
                  <Grid
                    alignItems="center"
                    className={classes.grid}
                    container
                    direction="row"
                    justify="center"
                  >
                    <Grid
                      className={classes.content}
                      item
                      lg={5}
                      md={7}
                      sm={7}
                      xs={12}
                    >
                      <div className={classes.content}>
                        {/* <div className={classes.contentHeader}>
                <IconButton
                  className={classes.backButton}
                  onClick={this.handleBack}
                >
                  <ArrowBackIcon />
                </IconButton>
              </div> */}
                        <div className={classes.contentBody}>
                          <form
                            id="sign-in-form"
                            className={classes.form}
                            onSubmit={e =>
                              this.handleSignIn(e, signIn, signInUser)
                            }
                          >
                            <Typography className={classes.title} variant="h2">
                              Sign in
                            </Typography>
                            {/* <Typography
                    className={classes.subtitle}
                    variant="body1"
                  >
                    Sign in with social media
                  </Typography>
                  <Button
                    className={classes.facebookButton}
                    color="primary"
                    onClick={this.handleSignIn}
                    size="large"
                    variant="contained"
                  >
                    <FacebookIcon className={classes.facebookIcon} />
                    Login with Facebook
                  </Button>
                  <Button
                    className={classes.googleButton}
                    onClick={this.handleSignIn}
                    size="large"
                    variant="contained"
                  >
                    <GoogleIcon className={classes.googleIcon} />
                    Login with Google
                  </Button>
                  <Typography
                    className={classes.sugestion}
                    variant="body1"
                  >
                    or login with email address
                  </Typography> */}
                            <div className={classes.fields}>
                              <TextField
                                className={classes.textField}
                                label="Email address"
                                name="email"
                                onChange={event =>
                                  this.handleFieldChange(
                                    "email",
                                    event.target.value
                                  )
                                }
                                type="text"
                                value={values.email}
                                variant="outlined"
                              />
                              {showEmailError && (
                                <Typography
                                  className={classes.fieldError}
                                  variant="body2"
                                >
                                  {errors.email[0]}
                                </Typography>
                              )}
                              <TextField
                                className={classes.textField}
                                label="Password"
                                name="password"
                                onChange={event =>
                                  this.handleFieldChange(
                                    "password",
                                    event.target.value
                                  )
                                }
                                type="password"
                                value={values.password}
                                variant="outlined"
                              />
                              {showPasswordError && (
                                <Typography
                                  className={classes.fieldError}
                                  variant="body2"
                                >
                                  {errors.password[0]}
                                </Typography>
                              )}
                            </div>
                            {submitError && (
                              <Typography
                                className={classes.submitError}
                                variant="body2"
                              >
                                {submitError}
                              </Typography>
                            )}
                            {loading ? (
                              <CircularProgress className={classes.progress} />
                            ) : (
                              <Button
                                className={classes.signInButton}
                                color="primary"
                                disabled={!isValid}
                                size="large"
                                variant="contained"
                                type="submit"
                                form="sign-in-form"
                              >
                                Sign in now
                              </Button>
                            )}

                            {/* <Typography
                    className={classes.signUp}
                    variant="body1"
                  >
                    Don't have an account?{' '}
                    <Link
                      className={classes.signUpUrl}
                      to="/sign-up"
                    >
                      Sign up
                    </Link>
                  </Typography> */}
                          </form>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              );
            }}
          </Mutation>
        )}
      </UserContext.Consumer>
    );
  }
}

SignIn.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(styles)
)(SignIn);
